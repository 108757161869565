class PardotService {
  constructor () {
    console.debug('PardotService')
  }

    private unlisten: any = null;
    private piEmail: string = null;
    initialize = (history:any = null, piAId:string = '', piCId:string = '', piHostname:string = '') => {
      if (history === null) {
        console.error('history is a required parameter')
        return
      }
      if (piAId === '') {
        console.error('piAId is a required parameter')
        return
      }
      if (piCId === '') {
        console.error('piCId is a required parameter')
        return
      }
      if (piHostname === '') {
        console.error('piHostname is a required parameter')
        return
      }
      (<any>window).piAId = piAId;
      (<any>window).piCId = piCId;
      (<any>window).piHostname = piHostname
      insertScript()
      this.unlisten = history.listen(this.track)
      console.debug('PardotService Initialized')
    }

    terminate = () => {
      this.unlisten && this.unlisten()
      console.debug('PardotService Terminated')
    }

    setPiEmail = (piEmail:string) => {
      this.piEmail = piEmail
    }

    track = (location:any, action:any) => {
      if (this.piEmail) {
        (<any>window).piEmail = this.piEmail;
        (<any>window).piGetParameterOverride()
      }
      (<any>window).piTracker(document.location.href)
    }
}
const insertScript = () => {
  var tag = document.createElement('script')
  tag.async = false
  tag.type = 'text/javascript'
  tag.text = `function piGetParameterOverride() {
            window.piGetParameter = function piGetParameter(e, t) {
                let tin=t;
                var t = t + "=";
                if (e.length > 0) {
                    var r = e.indexOf(t);
                    if (-1 != r) {
                        r += t.length;
                        var i = e.indexOf("&", r);
                        return -1 == i && (i = e.length), decodeURIComponent(e.substring(r, i));
                    }
                }
                if( tin === "pi_email" && piEmail)
                {
                    return decodeURIComponent(piEmail);
                }
                return null;
            }
        }
        (function() {
            function async_load(){
                        var s = document.createElement('script'); s.type = 'text/javascript';
                        s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
                        var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
            }
            if(window.attachEvent) { window.attachEvent('onload', async_load); }
            else { window.addEventListener('load', async_load, false); }
})();`

  document.body.appendChild(tag)
}

export const PardotTracker = new PardotService()
